<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="https://www.simg.com">
           SIMG MARMI E GRANITI
          </a>
        </li>
     
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{test | date: 'yyyy'}}
    </div>
  </div>
</footer>
